import { styled } from "@mui/material/styles";
import {
  alpha,
  Container,
  Grid,
  Paper,
  Toolbar,
  InputBase,
  Button,
  Typography,
  Box,
  Stack,
} from "@mui/material";
import React, { useEffect, useState, Suspense } from "react";
import { connect, useSelector } from "react-redux";
import supabase from "../lib/supabaseClient";
import Artist from "./Artist";
import SearchIcon from "@mui/icons-material/Search";
import useLocalStorage from "../lib/useLocalStorage";
import TopArtists from "./TopArtists";
import loadingIcon from "./../lib/loadingIcon";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor:
    theme.palette.mode === "dark"
      ? alpha(theme.palette.common.white, 0.15)
      : alpha(theme.palette.common.black, 0.15),

  "&:hover": {
    backgroundColor:
      theme.palette.mode === "dark"
        ? alpha(theme.palette.common.white, 0.25)
        : alpha(theme.palette.common.black, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export const ListArtists = () => {
  const [fetchError, setFetchError] = useState(null);
  const [artists, setArtists] = useState([]);
  const [artistsBackup, setArtistsBackup] = useState([]);
  const numPosts = useSelector((state) => state.user.numPosts);
  const [listenPost, setListenPosts] = useState(numPosts);
  const [searchStorage, setSearchStorage] = useLocalStorage(
    "searchStorage",
    ""
  );
  const [itemsToShow, setItemsToShow] = useState(3);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    if (artistsBackup.length === 0 || numPosts !== listenPost) {
      const getArtists = async () => {
        const { data, error } = await supabase
          .from("musicPosts")
          .select()
          .order("upVotesCount", { ascending: false });
        if (error) {
          setFetchError("Could not load data, try refreshing your page!");
          setArtists(null);
        }

        if (data) {
          setArtists(data);
          setArtistsBackup(data);
          setListenPosts(numPosts);
          setFetchError(null);
        }
      };
      getArtists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numPosts]);

  useEffect(() => {
    if (searchStorage === undefined) {
      setSearchStorage("");
    }
    if (searchStorage !== "") {
      const filteredSearch = artists.filter((artist) => {
        return (
          artist.artistName
            .toLowerCase()
            .includes(searchStorage.toLowerCase()) ||
          artist.city.toLowerCase().includes(searchStorage.toLowerCase()) ||
          artist.state.toLowerCase().includes(searchStorage.toLowerCase()) ||
          artist.genre.toLowerCase().includes(searchStorage.toLowerCase())
        );
      });
      setArtists(filteredSearch);
    } else {
      setArtists(artistsBackup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchStorage]);

  const showMoreItems = () => {
    setItemsToShow(itemsToShow + 3);
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      <Container sx={{ maxWidth: "66vw", paddingTop: "15px" }} maxWidth={false}>
        <Toolbar
          disableGutters
          id="mainToolbar"
          sx={{
            justifyContent: "center",
            display: "flex",
            justifyItems: "center",
          }}
        >
          <Search id="back-to-top-anchor">
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search..."
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => setSearchStorage(e.target.value)}
              value={searchStorage}
            />
          </Search>
        </Toolbar>
      </Container>
      {fetchError && <p>{fetchError}</p>}
      <Container sx={{ maxWidth: "66vw" }} maxWidth={false}>
        <Grid
          container
          className="listContainer"
          sx={{ gap: 3, marginTop: 5 }}
          alignItems="center"
          justifyContent="center"
          alignContent="center"
          justifyItems="center"
          textAlign="center"
          columns={3}
          flexWrap="wrap"
        >
          <Suspense fallback={"Loading..."}>
            {artists.slice(0, itemsToShow).map((artist, i) => (
              <Grid item minWidth="300px" maxWidth={false} key={artist.id}>
                <Item key={artist.id} sx={{ marginBottom: "10px" }}>
                  <Artist artist={artist} key={artist.id} />
                </Item>
              </Grid>
            ))}
          </Suspense>
          <Grid item width="100%">
            <Typography align="center">
              {
                artists.length > itemsToShow ? (
                  <Button
                    variant="outlined"
                    color="warning"
                    size="medium"
                    onClick={() => showMoreItems()}
                  >
                    Show More
                  </Button>
                ) : null
                // <Button
                //   variant="outlined"
                //   color="warning"
                //   size="medium"
                //   onClick={() => showMoreItems()}
                //   disabled
                // >
                //   Show More
                // </Button>
              }
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ListArtists);
