import { useEffect, useState } from "react";
import supabase from "../lib/supabaseClient";
import { Button, Typography } from "@mui/material";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";

export default function AvatarWidget({
  url = "",
  size = "",
  onUpload,
  sbUrl = "",
  user = "",
}) {
  const [avatarUrl, setAvatarUrl] = useState(url);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (url)
      setAvatarUrl(
        "https://wgnmpgxnshaydtslnahz.supabase.co/storage/v1/object/public/user-avatarphotos/" +
          url
      );
  }, [url]);

  const uploadAvatar = async (event) => {
    try {
      setUploading(true);

      if (!event.target.files || event.target.files.length === 0) {
        throw new Error("Please select an image to upload.");
      }

      const file = event.target.files[0];
      const fileExt = file.name.split(".").pop();
      const fileName = user + `-${Math.random()}.${fileExt}`;
      const filePath = `${fileName}`;

      if (url) {
        let { error: uploadError1 } = await supabase.storage
          .from("user-avatarphotos")
          .remove([url]);

        if (uploadError1) {
          throw uploadError1;
        }
      }

      if (file.size > 5242880) {
        throw new Error("Please select an image that is 5MB or smaller.");
      }

      let { error: uploadError } = await supabase.storage
        .from("user-avatarphotos")
        .upload(filePath, file);

      if (uploadError) {
        throw uploadError;
      }

      onUpload(filePath);
    } catch (error) {
      alert(error.message);
    } finally {
      setUploading(false);
    }
  };

  return (
    <>
      {uploading ? (
        <Typography align="center">Uploading...</Typography>
      ) : (
        <>
          {avatarUrl && (
            <img
              src={avatarUrl ? avatarUrl : null}
              alt={avatarUrl ? "Avatar" : "No image"}
              className="avatar image"
              style={{
                height: size,
                width: size,
                marginBottom: "10px",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          )}
          <Button
            variant="outlined"
            color="primary"
            component="label"
            endIcon={<InsertPhotoIcon />}
            sx={{ textTransform: "none" }}
          >
            Add Photo
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={uploadAvatar}
              disabled={uploading}
            />
          </Button>
        </>
      )}
    </>
  );
}
