import React from "react";
import { Container, Box, Typography } from "@mui/material";
import ScrollToTopButton from "./ScrollToTopButton";

const Footer = () => {
  return (
    <Box
      width="100%"
      position="flex"
      sx={{ bottom: 0, marginTop: "30px", marginBottom: "10px" }}
    >
      <Typography
        fontSize="10px"
        sx={{
          textAlign: "center",
          fontStyle: "italic",
          textTransform: "lowercase",
        }}
      >
        "no majors, no algorithms, just 🔥"
      </Typography>
      {/* <ScrollToTopButton /> */}
      <Typography
        fontSize="10px"
        sx={{
          textAlign: "center",
          fontStyle: "italic",
          textTransform: "lowercase",
        }}
      >
        powered by{" "}
        <a href="https://dallasopenmic.com" target="_blank" rel="noreferrer">
          <u>Dallas Open Mic</u>
        </a>
      </Typography>

      <Container
        style={{
          display: "flex",

          justifyContent: "center",
          maxWidth: "100%",
          margin: "0 auto",
        }}
      ></Container>
    </Box>
  );
};
export default Footer;
