import React, { useRef, useEffect, memo, useState } from "react";

const Events = () => {
  const scriptToInject = `
    <script type="text/javascript">
      var exampleCallback = function () {
        console.log("Order complete!");
      };

      window.EBWidgets.createWidget({
        // Required
        widgetType: "checkout",
        eventId: "133602391349",
        iframeContainerId: "eventbrite-widget-container-133602391349",

        // Optional
        iframeContainerHeight: 425, // Widget height in pixels. Defaults to a minimum of 425px if not provided
        onOrderComplete: exampleCallback, // Method called when an order has successfully completed
      });
    </script>`;

  const InjectScript = memo(({ script }) => {
    const divRef = useRef(null);

    useEffect(() => {
      if (divRef.current === null) {
        return;
      }
      // create a contextual fragment that will execute the script
      // beware of security concerns!!
      const doc = document.createRange().createContextualFragment(script);

      // clear the div HTML, and append the doc fragment with the script
      divRef.current.innerHTML = "";
      divRef.current.appendChild(doc);
    });

    return <div ref={divRef} />;
  });

  return (
    <div>
      <InjectScript script={scriptToInject} />
    </div>
  );
};

export default Events;
