import React, { useState, useEffect } from "react";
import { Container, Grid, Typography, Button } from "@mui/material";
import supabase from "./../lib/supabaseClient";
import EditProfileButton from "./EditProfileButton";
import LinkIcon from "@mui/icons-material/Link";
import { useSelector, useDispatch } from "react-redux";
import { Artistv2 } from "./Artistv2";
import { styled } from "@mui/material/styles";
import { setAvatarLink } from "../redux/userSlicer";
import FullScreenAvatar from "./FullScreenAvatar";
import { Helmet } from "react-helmet";

const CustomButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const Profile = () => {
  const user = useSelector((state) => state.user.user);
  const profileUpdate = useSelector((state) => state.user.profileUpdate);
  const [profileInfo, setProfileInfo] = useState();
  const [showProfile, setShowProfile] = useState(true);
  const [postsOrLikesToggle, setToggle] = useState(true);
  const [profileLikes, setProfileLikes] = useState();
  const [profilePosts, setProfilePosts] = useState();
  var parser = window.location.href.split("/");
  let url = parser[3];
  let userWebsiteLink;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!profileInfo || !profileUpdate) {
      const getProfile = async () => {
        try {
          const { data, error } = await supabase
            .from("userProfiles")
            .select(
              "id, email, avatarUrl, userBio, userWebsite, username, coverUrl, displayName"
            )
            .eq("username", url);

          if (data.length > 0) {
            setProfileInfo(data);
          } else {
            setShowProfile(false);
          }
        } catch (err) {
          console.log(err);
        }
      };
      getProfile();
    }
  }, [!profileUpdate, user]);

  const getProfileLikes = async () => {
    const { data, error } = await supabase
      .from("musicPosts")
      .select()
      .textSearch("likedByUserId", profileInfo[0].id)
      .order("upVotesCount", { ascending: false });

    if (data.length > 0) {
      setProfileLikes(data);
    }
  };
  const getProfilePosts = async () => {
    const { data, error } = await supabase
      .from("musicPosts")
      .select()
      .match({ userId: profileInfo[0].id })
      .order("upVotesCount", { ascending: false });

    if (data.length > 0) {
      setProfilePosts(data);
    }
  };

  if (profileInfo) {
    if (profileInfo[0].userWebsite) {
      userWebsiteLink = profileInfo[0].userWebsite;
      if (
        !userWebsiteLink.includes("https:") &&
        !userWebsiteLink.includes("http:")
      ) {
        userWebsiteLink = "https://" + profileInfo[0].userWebsite;
      }
    }

    if (!profileLikes) {
      getProfileLikes();
    }

    if (!profilePosts) {
      getProfilePosts();
    }
  }

  useEffect(() => {
    if (profileInfo) {
      if (profileInfo[0].avatarUrl) {
        dispatch(
          setAvatarLink(
            "https://wgnmpgxnshaydtslnahz.supabase.co/storage/v1/object/public/user-avatarphotos/" +
              profileInfo[0].avatarUrl
          )
        );
      }
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>IndieCharts.io | {url}</title>
      </Helmet>
      <Grid marginTop="30px"></Grid>
      {profileInfo && (
        <Container align="center" justify="center">
          <>
            <Grid container>
              <Grid item xs={12}>
                {profileInfo[0].coverUrl && (
                  <img alt="cover" src={profileInfo[0].coverUrl} />
                )}
              </Grid>
            </Grid>
            <Grid container display="flex" justifyContent="center">
              <Grid item xs={12}>
                {profileInfo[0].avatarUrl && (
                  <FullScreenAvatar
                    avatarLink={
                      "https://wgnmpgxnshaydtslnahz.supabase.co/storage/v1/object/public/user-avatarphotos/" +
                      profileInfo[0].avatarUrl
                    }
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">
                  {profileInfo[0].displayName}
                </Typography>
                <Typography variant="caption">
                  @{profileInfo[0].username}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sx={{ justifyItems: "center" }}>
                <Typography
                  marginTop="30px"
                  display="flex"
                  justifyContent="center"
                >
                  {profileInfo[0].userWebsite && <LinkIcon />}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`${userWebsiteLink}`}
                  >
                    {profileInfo[0].userWebsite}
                  </a>
                </Typography>
                <Typography
                  marginTop="20px"
                  marginBottom="30px"
                  maxWidth="300px"
                >
                  {profileInfo[0].userBio}
                </Typography>
              </Grid>
            </Grid>
            {user && user.email === profileInfo[0].email && (
              <EditProfileButton
                avatar={
                  profileInfo[0].avatarUrl ? profileInfo[0].avatarUrl : ""
                }
                bio={profileInfo[0].userBio ? profileInfo[0].userBio : ""}
                website={
                  profileInfo[0].userWebsite ? profileInfo[0].userWebsite : ""
                }
                name={profileInfo[0].username ? profileInfo[0].username : ""}
                display={
                  profileInfo[0].displayName ? profileInfo[0].displayName : ""
                }
                cover={profileInfo[0].coverUrl ? profileInfo[0].coverUrl : ""}
              />
            )}
            <Grid container marginTop="30px" justifyContent="center">
              <Grid item xs={3}>
                <CustomButton
                  disableRipple
                  autoFocus
                  onClick={() => setToggle(true)}
                >
                  {postsOrLikesToggle ? (
                    <Typography
                      sx={{
                        textDecoration: "underline",
                        fontWeight: "bold",
                        textTransform: "none",
                      }}
                    >
                      Posts
                    </Typography>
                  ) : (
                    <Typography sx={{ textTransform: "none" }}>
                      Posts
                    </Typography>
                  )}
                </CustomButton>
              </Grid>
              <Grid item xs={3}>
                <CustomButton disableRipple onClick={() => setToggle(false)}>
                  {!postsOrLikesToggle ? (
                    <Typography
                      sx={{
                        textDecoration: "underline",
                        fontWeight: "bold",
                        textTransform: "none",
                      }}
                    >
                      Likes
                    </Typography>
                  ) : (
                    <Typography sx={{ textTransform: "none" }}>
                      Likes
                    </Typography>
                  )}
                </CustomButton>
              </Grid>
            </Grid>
            <Grid container marginTop="15px">
              <Grid item xs={12}>
                {postsOrLikesToggle && profilePosts && (
                  <>
                    {profilePosts.map((artist, i) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        key={i}
                        borderBottom="solid .5px grey"
                      >
                        <Artistv2 artist={artist} key={artist.id} ranking={i} />
                      </Grid>
                    ))}
                  </>
                )}
                {postsOrLikesToggle && !profilePosts && (
                  <>
                    <Typography>
                      This user has not posted any music yet.
                    </Typography>
                  </>
                )}
                {!postsOrLikesToggle && profileLikes && (
                  <>
                    {profileLikes.map((artist, i) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        key={i}
                        borderBottom="solid .5px grey"
                      >
                        <Artistv2 artist={artist} key={artist.id} ranking={i} />
                      </Grid>
                    ))}
                  </>
                )}

                {!postsOrLikesToggle && !profileLikes && (
                  <>
                    <Typography>
                      This user has not liked any posts yet.
                    </Typography>
                  </>
                )}
              </Grid>
            </Grid>
          </>
        </Container>
      )}
      {!showProfile && (
        <>
          <Container align="center" justify="center">
            <Typography variant="h4">This profile does not exist.</Typography>
            <Typography variant="subtitle1">
              Try searching for another.
            </Typography>
          </Container>
        </>
      )}
    </>
  );
};

export default Profile;
