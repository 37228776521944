import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { setCity } from "../redux/userSlicer.js";
import {
  Box,
  Typography,
  Grid,
  Link,
  Button,
  IconButton,
  Container,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import supabase from "./../lib/supabaseClient";
import { Artistv2 } from "./Artistv2";

const style = {
  align: "center",
  textAlign: "center",
  position: "flex",
  alignItems: "center",
  justifyContent: "center",
  maxWidth: "90%",
  margin: "auto",
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const TopArtists2 = ({}) => {
  const [curSearch, setCurSearch] = useState("");
  const [curColumn, setCurColumn] = useState("");
  const [artists, setArtists] = useState([]);
  const [curUrl, setCurUrl] = useState(window.location.href.split("/"));
  const curUrl1 = window.location.href.split("/");

  if (curUrl[4] !== curUrl1[4]) {
    setCurUrl(curUrl1);
    setCurSearch(curUrl1[4].replace("%20", " "));
    setCurColumn(curUrl1[3].toLowerCase());
  }

  useEffect(() => {
    const getArtists = async () => {
      const { data, error } = await supabase
        .from("musicPosts")
        .select()
        .eq(curColumn, curSearch);

      if (error) {
        setArtists(null);
      }

      if (data) {
        setArtists(data);
      }
      console.log(curColumn, curSearch);
    };

    getArtists();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curUrl]);

  return (
    <div>
      {artists && (
        <Box sx={style}>
          <Stack
            direction="column"
            // divider={<Divider orientation="horizontal" flexItem />}
            spacing={0}
            marginTop="25px"
          >
            <Typography variant="h5" marginBottom="5px" fontWeight="bold">
              Top 🔥 in {curUrl[4].replace("%20", " ")}:
            </Typography>

            {artists.map((artist, i) => (
              <Grid item xs={12} key={i} borderBottom="solid .5px grey">
                <Artistv2
                  index={i}
                  artist={artist}
                  key={artist.id}
                  ranking={i}
                />
              </Grid>

              // <Typography key={i}>
              //   <Button
              //     target="_blank"
              //     href={artist.igLink}
              //     disableRipple
              //     sx={{
              //       boxShadow: "none",
              //       textTransform: "none",
              //       fontSize: 16,
              //       color: "inherit",

              //       "&:hover": {
              //         fontSize: 20,
              //         fontWeight: "bold",
              //         backgroundColor: "transparent",
              //       },
              //     }}
              //   >
              //     {i + 1}. {artist.artistName}
              //   </Button>
              //   <Grid
              //     className="likeButtonGrid"
              //     container
              //     justifyContent="center"
              //     alignItems="center"
              //     display="inline"
              //   >
              //     <span>
              //       <IconButton
              //         key={`${artist.id}likeButton`}
              //         aria-label="Fire"
              //         color="inherit"
              //         sx={{ margin: "0" }}
              //         disableRipple
              //       >
              //         🔥
              //         <Typography
              //           color="inherit"
              //           textTransform="lowercase"
              //           marginLeft="2px"
              //           fontSize="15px"
              //           fontWeight="bold"
              //         >
              //           {artist.upVotesCount}
              //         </Typography>
              //       </IconButton>
              //     </span>
              //   </Grid>
              // </Typography>
            ))}
          </Stack>
        </Box>
      )}
      {!artists && (
        <>
          <Box sx={style} marginTop="45px">
            <Stack
              direction="column"
              // divider={<Divider orientation="horizontal" flexItem />}
              spacing={0}
            >
              <Typography variant="h5" marginBottom="5px" fontWeight="bold">
                Top 🔥 in {curUrl[4].replace("%20", " ")}:
              </Typography>
              <Typography
                sx={{
                  boxShadow: "none",
                  textTransform: "none",
                  fontSize: 16,
                  color: "inherit",
                }}
              >
                Nobody yet!
              </Typography>
            </Stack>
          </Box>
        </>
      )}
    </div>
  );
};

export default TopArtists2;
