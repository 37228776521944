// @ts-nocheck
import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  backgroundColor: "black",
  boxShadow: 24,
  p: 1,
  pt: 5,
  borderRadius: "15px",
  outline: "none",
};

export default function SubscribeButton() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const user = useSelector((state) => state.user.user);

  return (
    <div>
      {user && (
        <>
          <Button
            color="warning"
            size="small"
            style={{
              fontWeight: "700",
            }}
            onClick={handleOpen}
          >
            Subscribe
          </Button>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                {/* <Paper elevation={12}> */}
                <stripe-pricing-table
                  pricing-table-id="prctbl_1LktiAEy2XzfhjRvBDaDI5fa"
                  publishable-key="pk_live_51LkV0iEy2XzfhjRvKO3CV8wpAKIC9LrCSt2RMGXPgDXwMG4DWXZ411Ah5WLEkc9z7QsNvoRjkoCuVdrGZcCoqtpE00XPzlDBIe"
                  customer-email={user.email}
                ></stripe-pricing-table>
                {/* </Paper> */}
              </Box>
            </Fade>
          </Modal>
        </>
      )}
    </div>
  );
}
