import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Avatar from "@mui/material/Avatar";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  outline: "none",
};

export default function FullScreenAvatar(avatarLink = "") {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button
        onClick={handleOpen}
        disableRipple
        sx={{ "&:hover": { backgroundColor: "transparent" } }}
      >
        <Avatar
          alt="Profile Picture"
          src={avatarLink.avatarLink}
          sx={{ width: 134, height: 134 }}
        />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Avatar
            alt="Profile Picture"
            src={avatarLink.avatarLink}
            sx={{ width: "100%", height: "100%" }}
          />
        </Box>
      </Modal>
    </div>
  );
}
