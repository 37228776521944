import { connect, useDispatch, useSelector } from "react-redux";
import * as React from "react";
import { styled } from "@mui/material/styles";
import supabase from "../lib/supabaseClient";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useEffect, useRef, useState } from "react";
import {
  IconButton,
  Typography,
  CardActions,
  Card,
  CardContent,
  Collapse,
  Grid,
  Tooltip,
} from "@mui/material";
import ShareButton from "./ShareButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { setNumPosts } from "../redux/userSlicer";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import loadingIcon from "../lib/loadingIcon";
import { Link } from "react-router-dom";
import Zoom from "@mui/material/Zoom";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const Artist = ({ artist, index }) => {
  const [expanded, setExpanded] = React.useState(false);
  const [alreadyVoted, setAlreadyVoted] = React.useState(false);
  const [upVotes, setUpVotes] = React.useState(artist.upVotesCount);
  const votedRef = useRef(null);
  const sTheme = useSelector((state) => state.theme.sTheme);
  const user = useSelector((state) => state.user.user);
  const numPosts = useSelector((state) => state.user.numPosts);
  const [newSongLink, setSongLink] = useState("");
  const dispatch = useDispatch();
  const date = new Date();
  let likedByUsers = artist.likedByUserId;
  let artistUrl = artist.artistName.replace(/\s+/g, "-");
  let [disableDelete, setDisableDelete] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const checkSongLink = () => {
    if (artist.songLink.includes("spotify.com")) {
      const spotifySongLink =
        "https://open.spotify.com/embed/" +
        artist.songLink.substring(
          artist.songLink.indexOf("track/"),
          artist.songLink.indexOf("?")
        ) +
        "?utm_source=generator&theme=" +
        sTheme;
      setSongLink(spotifySongLink);
    }

    if (artist.songLink.includes("tidal.com")) {
      const tidalSongLink =
        "https://embed.tidal.com/tracks/" +
        artist.songLink.substring(artist.songLink.indexOf("k/") + 2) +
        "?layout=gridify";
      setSongLink(tidalSongLink);
    }

    if (artist.songLink.includes("youtube.com")) {
      const youtubeSongLink =
        "https://www.youtube.com/embed/" +
        artist.songLink.substring(artist.songLink.indexOf("?v=") + 3);
      setSongLink(youtubeSongLink);
    }
    if (artist.songLink.includes("youtu.be")) {
      const youtubeSongLink =
        "https://www.youtube.com/embed/" +
        artist.songLink.substring(artist.songLink.indexOf(".be/") + 4);
      setSongLink(youtubeSongLink);
    }
  };

  useEffect(() => {
    if (
      !newSongLink.includes("tidal.com") ||
      !newSongLink.includes("spotify.com") ||
      !newSongLink.includes("youtube.com") ||
      !newSongLink.includes("youtu.be")
    ) {
      checkSongLink();
    }
  }, []);

  const handleUpVote = async (id, upVotes) => {
    if (!alreadyVoted) {
      if (likedByUsers !== null) {
        likedByUsers = likedByUsers + ", " + user.id;
      } else {
        likedByUsers = user.id;
      }
      await supabase
        .from("musicPosts")
        .update({
          upVotesCount: upVotes + 1,
          updatedAt: date.toUTCString(),
          likedByUserId: likedByUsers,
        })
        .eq("id", id);
      setAlreadyVoted(true);
      setUpVotes((upVotes) => upVotes + 1);
    }
  };

  const handleDelete = async (postId) => {
    if (disableDelete === false) {
      setDisableDelete(true);
      try {
        await supabase.from("musicPosts").delete().match({ id: postId });
        await supabase
          .from("users")
          .update({
            numPosted: numPosts - 1,
            updatedAt: date.toUTCString(),
          })
          .eq("id", user.id);
        dispatch(setNumPosts(numPosts - 1));
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (user) {
      if (user.id === artist.userId) {
        setAlreadyVoted(true);
        return;
      } else {
        if (artist.likedByUserId !== null) {
          if (artist.likedByUserId.includes(user.id)) {
            setAlreadyVoted(true);
            return;
          }
        }
      }
    }
  }, [user, alreadyVoted]);

  return (
    <div>
      <Card sx={{ maxWidth: 345 }}>
        <Grid
          container
          display="flex"
          direction="row"
          justifySelf="center"
          alignItems="center"
          alignContent="center"
          justifyItems="center"
          textAlign="center"
          justifyContent="center"
          marginTop="5px"
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            display="flex"
            align="center"
            textAlign="center"
            sx={{ textDecoration: "underline" }}
          >
            <Link
              key={`${artist.artistName}link`}
              to={`/Artist/${artistUrl}`}
              //state={{ artist: artist }}
            >
              {artist.artistName}
            </Link>
          </Typography>
        </Grid>

        <Typography
          variant="overline"
          display="block"
          fontWeight="bold"
          fontSize="10px"
          paddingLeft="10px"
          paddingRight="10px"
          marginBottom="-10px"
        >
          {artist.city}, {artist.state}
        </Typography>
        <Typography
          variant="overline"
          display="block"
          fontWeight="light"
          fontSize="8px"
          paddingLeft="10px"
          paddingRight="10px"
        >
          {artist.genre}
        </Typography>

        {user ? (
          <>
            <Grid
              className="likeButtonGrid"
              container
              justifyContent="center"
              alignItems="center"
            >
              <Tooltip title="Vote" arrow TransitionComponent={Zoom}>
                <span>
                  <IconButton
                    key={`${artist.id}_Like_Button`}
                    onClick={() => handleUpVote(artist.id, upVotes)}
                    disabled={alreadyVoted}
                    ref={votedRef}
                    aria-label="Fire"
                    color="inherit"
                  >
                    🔥
                    <Typography
                      color="inherit"
                      textTransform="lowercase"
                      marginLeft="2px"
                      fontSize="15px"
                      fontWeight="bold"
                    >
                      {upVotes}
                    </Typography>
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </>
        ) : (
          <>
            <Grid
              className="likeButtonGrid"
              container
              justifyContent="center"
              alignItems="center"
            >
              <span>
                <Tooltip
                  title="Log In To Vote!"
                  arrow
                  TransitionComponent={Zoom}
                >
                  <IconButton
                    key={`${artist.id}likeButton`}
                    ref={votedRef}
                    aria-label="Fire"
                    color="inherit"
                    sx={{ margin: "0" }}
                    disableRipple
                  >
                    🔥
                    <Typography
                      color="inherit"
                      textTransform="lowercase"
                      marginLeft="2px"
                      fontSize="15px"
                      fontWeight="bold"
                    >
                      {upVotes}
                    </Typography>
                  </IconButton>
                </Tooltip>
              </span>
            </Grid>
          </>
        )}

        <CardActions disableSpacing sx={{ marginTop: "-10px" }}>
          {user
            ? user.id === artist.userId && (
                <Tooltip title="Delete Post" arrow TransitionComponent={Zoom}>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      handleDelete(artist.id);
                    }}
                    disabled={disableDelete}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )
            : null}

          <ShareButton artistName={artist.artistName} />

          <Tooltip title="Follow on Instagram" arrow TransitionComponent={Zoom}>
            <IconButton
              href={artist.igLink}
              target="_blank"
              aria-label="instagram"
              color="inherit"
            >
              <InstagramIcon />
            </IconButton>
          </Tooltip>

          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            sx={{}}
          >
            <Tooltip title="Play Music" arrow TransitionComponent={Zoom}>
              <PlayCircleIcon />
            </Tooltip>
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Grid
              container
              display="flex"
              direction="row"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              spacing={0}
              width="auto"
              margin="auto"
              alignContent="center"
              justifyItems="center"
            >
              {newSongLink.includes("spotify.com") && (
                <iframe
                  onLoad={loadingIcon}
                  src={newSongLink}
                  width="95%"
                  height="259px"
                  scrolling="no"
                  frameBorder="0"
                  border-radius="0px"
                  title={`${artist.id} Spotify Link`}
                  allow="clipboard-write; encrypted-media; picture-in-picture"
                  loading="lazy"
                ></iframe>
              )}

              {newSongLink.includes("tidal.com") && (
                <iframe
                  onLoad={loadingIcon}
                  src={newSongLink}
                  frameBorder="0"
                  scrolling="no"
                  title={`${artist.id} Tidal Link`}
                  width="95%"
                  height="95%"
                  loading="lazy"
                ></iframe>
              )}

              {newSongLink.includes("youtube.com") && (
                <iframe
                  onLoad={loadingIcon}
                  width="95%"
                  height="95%"
                  loading="lazy"
                  src={newSongLink}
                  title={`${artist.id} Youtube Link`}
                  frameBorder="0"
                  scrolling="no"
                  allowFullScreen
                  // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              )}
            </Grid>
          </CardContent>
        </Collapse>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Artist);
