import { createSlice } from "@reduxjs/toolkit";
import supabase from "../lib/supabaseClient";
const date = new Date();

const initialState = {
  user: {},
  numPosts: 0,
  isLoggedIn: false,
  postsLiked: {},
  isSubscribed: false,
  city: "",
  username: "",
  profileUpdate: false,
  avatarLink: "",
};

const updateNumPosted = async (num, id) => {
  await supabase
    .from("users")
    .update({
      numPosted: num,
      updatedAt: date.toUTCString(),
    })
    .eq("id", id);
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addPost: (state) => {
      if (state.numPosts >= 0) {
        state.numPosts = state.numPosts + 1;
      } else {
        state.numPosts = 1;
      }
      // @ts-ignore
      updateNumPosted(state.numPosts, state.user.id);
    },
    deletePost: (state) => {
      if (state.numPosts >= 0) {
        state.numPosts = state.numPosts - 1;
      } else {
        state.numPosts = 0;
      }
      // @ts-ignore
      updateNumPosted(state.numPosts, state.user.id);
    },
    loggedInTrue: (state) => {
      state.isLoggedIn = true;
    },
    loggedInFalse: (state) => {
      state.isLoggedIn = false;
    },
    setUser: (state, value) => {
      state.user = value.payload;
    },
    subscribedTrue: (state) => {
      state.isSubscribed = true;
    },
    subscribedFalse: (state) => {
      state.isSubscribed = false;
    },
    setNumPosts: (state, value) => {
      state.numPosts = value.payload;
    },
    setCity: (state, value) => {
      state.city = value.payload;
    },
    setUsername: (state, value) => {
      state.username = value.payload;
    },
    updatingProfile: (state, value) => {
      state.profileUpdate = value.payload;
    },
    setAvatarLink: (state, value) => {
      state.avatarLink = value.payload;
    },
  },
});

export const {
  subscribedTrue,
  subscribedFalse,
  deletePost,
  setUser,
  addPost,
  loggedInTrue,
  loggedInFalse,
  setNumPosts,
  setCity,
  setUsername,
  updatingProfile,
  setAvatarLink,
} = userSlice.actions;

export default userSlice.reducer;
