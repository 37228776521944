import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { setCity } from "../redux/userSlicer.js";
import { Box, Typography, Grid, Link, Button } from "@mui/material";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

const style = {
  margin: 0,
  top: 350,
  right: "auto",
  bottom: "auto",
  left: 40,
  position: "fixed",
  display: { md: "none", xs: "none", lg: "block" },
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const TopArtists = ({ artists }) => {
  const city = useSelector((state) => state.user.city);
  const dispatch = useDispatch();
  const [userCity, setUserCity] = useState("");
  const [cityArtists, setCityArtists] = useState();

  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    dispatch(setCity(res.data.city));
    setUserCity(city);
    setCityArtists(
      artists.filter((artist) => {
        return artist.city.toLowerCase().includes(city.toLowerCase());
      })
    );
  };

  useEffect(() => {
    getData();
  }, [city != userCity]);

  return (
    <div>
      {city && cityArtists && (
        <Box sx={style} marginTop="45px">
          <Stack
            direction="column"
            // divider={<Divider orientation="horizontal" flexItem />}
            spacing={0}
          >
            <Typography variant="h5" marginBottom="5px" fontWeight="bold">
              Top 🔥 in {city}:
            </Typography>
            {cityArtists.slice(0, 5).map((artist, i) => (
              <Typography key={i}>
                <Button
                  target="_blank"
                  href={artist.igLink}
                  disableRipple
                  sx={{
                    boxShadow: "none",
                    textTransform: "none",
                    fontSize: 16,
                    color: "inherit",

                    "&:hover": {
                      fontSize: 20,
                      fontWeight: "bold",
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  {i + 1}. {artist.artistName}
                </Button>
              </Typography>
            ))}
          </Stack>
        </Box>
      )}
      {!cityArtists && (
        <>
          <Box sx={style} marginTop="45px">
            <Stack
              direction="column"
              // divider={<Divider orientation="horizontal" flexItem />}
              spacing={0}
            >
              <Typography
                variant="h5"
                marginBottom="5px"
                align="left"
                textAlign="left"
                fontWeight="bold"
              >
                Top 🔥 in {city}
              </Typography>
              <Typography
                sx={{
                  boxShadow: "none",
                  textTransform: "none",
                  fontSize: 16,
                  color: "inherit",
                }}
              >
                Nobody yet!
              </Typography>
            </Stack>
          </Box>
        </>
      )}
    </div>
  );
};

export default TopArtists;
