import { connect, useDispatch, useSelector } from "react-redux";
import * as React from "react";
import { styled } from "@mui/material/styles";
import supabase from "../lib/supabaseClient";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useEffect, useRef, useState } from "react";
import { IconButton, Typography, Grid, Tooltip, Button } from "@mui/material";
import ShareButton from "./ShareButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { setNumPosts } from "../redux/userSlicer";
import loadingIcon from "../lib/loadingIcon";
import Zoom from "@mui/material/Zoom";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const Artistv2 = ({ artist, index, ranking }) => {
  const [expanded, setExpanded] = React.useState(false);
  const [alreadyVoted, setAlreadyVoted] = React.useState(false);
  const [upVotes, setUpVotes] = React.useState(artist.upVotesCount);
  const votedRef = useRef(null);
  const sTheme = useSelector((state) => state.theme.sTheme);
  const user = useSelector((state) => state.user.user);
  const numPosts = useSelector((state) => state.user.numPosts);
  const [newSongLink, setSongLink] = useState("");
  const dispatch = useDispatch();
  const date = new Date();
  let likedByUsers = artist.likedByUserId;
  let artistUrl = artist.artistName.replace(/\s+/g, "-");
  let [disableDelete, setDisableDelete] = React.useState(false);
  const theme = useTheme();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const checkSongLink = () => {
    if (artist.songLink.includes("spotify.com")) {
      const spotifySongLink =
        "https://open.spotify.com/embed/" +
        artist.songLink.substring(
          artist.songLink.indexOf("track/"),
          artist.songLink.indexOf("?")
        ) +
        "?utm_source=generator&theme=" +
        sTheme;
      setSongLink(spotifySongLink);
    }

    if (artist.songLink.includes("tidal.com")) {
      const tidalSongLink =
        "https://embed.tidal.com/tracks/" +
        artist.songLink.substring(artist.songLink.indexOf("k/") + 2) +
        "?layout=gridify";
      setSongLink(tidalSongLink);
    }

    if (artist.songLink.includes("youtube.com")) {
      const youtubeSongLink =
        "https://www.youtube.com/embed/" +
        artist.songLink.substring(artist.songLink.indexOf("?v=") + 3);
      setSongLink(youtubeSongLink);
    }
    if (artist.songLink.includes("youtu.be")) {
      const youtubeSongLink =
        "https://www.youtube.com/embed/" +
        artist.songLink.substring(artist.songLink.indexOf(".be/") + 4);
      setSongLink(youtubeSongLink);
    }
  };

  useEffect(() => {
    if (
      !newSongLink.includes("tidal.com") ||
      !newSongLink.includes("spotify.com") ||
      !newSongLink.includes("youtube.com") ||
      !newSongLink.includes("youtu.be")
    ) {
      checkSongLink();
    }
  }, []);

  const handleUpVote = async (id, upVotes) => {
    if (!alreadyVoted) {
      if (likedByUsers !== null) {
        likedByUsers = likedByUsers + ", " + user.id;
      } else {
        likedByUsers = user.id;
      }
      await supabase
        .from("musicPosts")
        .update({
          upVotesCount: upVotes + 1,
          updatedAt: date.toUTCString(),
          likedByUserId: likedByUsers,
        })
        .eq("id", id);
      setAlreadyVoted(true);
      setUpVotes((upVotes) => upVotes + 1);
    }
  };

  const handleDelete = async (postId) => {
    if (disableDelete === false) {
      setDisableDelete(true);
      try {
        await supabase.from("musicPosts").delete().match({ id: postId });
        await supabase
          .from("users")
          .update({
            numPosted: numPosts - 1,
            updatedAt: date.toUTCString(),
          })
          .eq("id", user.id);
        dispatch(setNumPosts(numPosts - 1));
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (user) {
      if (user.id === artist.userId) {
        setAlreadyVoted(true);
        return;
      } else {
        if (artist.likedByUserId !== null) {
          if (artist.likedByUserId.includes(user.id)) {
            setAlreadyVoted(true);
            return;
          }
        }
      }
    }
  }, [user, alreadyVoted]);

  return (
    <div>
      <Grid
        container
        direction="row"
        align="center"
        justify="center"
        alignItems="center"
        justifyContent="center"
        height="200px"
      >
        <Grid item xs={1.5}>
          <Typography variant="h4" fontFamily="Helvetica Neue">
            {ranking + 1}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          {newSongLink.includes("spotify.com") && (
            <iframe
              onLoad={loadingIcon}
              src={newSongLink}
              width="95%"
              height="152px"
              scrolling="no"
              frameBorder="0"
              title={`${artist.id} Spotify Link`}
              allow="clipboard-write; encrypted-media; picture-in-picture"
              loading="lazy"
              allowFullScreen
            ></iframe>
          )}
          {newSongLink.includes("tidal.com") && (
            <iframe
              onLoad={loadingIcon}
              src={newSongLink}
              frameBorder="0"
              scrolling="no"
              title={`${artist.id} Tidal Link`}
              width="95%"
              height="95%"
              loading="lazy"
            ></iframe>
          )}
          {newSongLink.includes("youtube.com") && (
            <iframe
              onLoad={loadingIcon}
              width="95%"
              height="95%"
              loading="lazy"
              src={newSongLink}
              title={`${artist.id} Youtube Link`}
              frameBorder="0"
              scrolling="no"
              allowFullScreen
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          )}
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant="h5"
            fontWeight="bold"
            display="block"
            align="center"
            textAlign="center"
            sx={{
              "&:hover": {
                backgroundColor: "transparent",
                textDecoration: "underline",
              },
            }}
          >
            <a
              color="inherit"
              key={`${artist.artistName}songLink`}
              href={artist.songLink}
              target="_blank"
              rel="noreferrer"
            >
              {artist.songName}
            </a>
          </Typography>
          <Typography
            variant="overline"
            fontSize="15px"
            display="block"
            align="center"
            textAlign="center"
            marginTop="-12px"
            marginBottom="-12px"
            sx={{
              boxShadow: "none",
              textTransform: "none",
              fontSize: 16,
              color: "inherit",
              "&:hover": {
                backgroundColor: "transparent",
                textDecoration: "underline",
              },
            }}
          >
            <a
              color="inherit"
              key={`${artist.artistName}link`}
              href={artist.igLink}
              target="_blank"
              rel="noreferrer"
            >
              {artist.artistName}
            </a>
          </Typography>
          <Typography
            variant="overline"
            display="block-inline"
            fontSize="10px"
            sx={{
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            <Link
              color="inherit"
              key={`${artist.city}link`}
              to={`/City/${artist.city}`}
            >
              {artist.city}
            </Link>
          </Typography>
          ,{" "}
          <Typography
            variant="overline"
            display="block-inline"
            fontSize="10px"
            sx={{
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            <Link
              color="inherit"
              key={`${artist.state}link`}
              to={`/State/${artist.state}`}
            >
              {artist.state}
            </Link>
          </Typography>
          <Typography
            variant="overline"
            display="block"
            fontWeight="light"
            fontSize="8px"
            marginTop="-10px"
            marginBottom="-10px"
            sx={{
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            <Link
              color="inherit"
              key={`${artist.genre}link`}
              to={`/Genre/${artist.genre}`}
            >
              {artist.genre}
            </Link>
          </Typography>
          {user ? (
            <>
              <Grid
                className="likeButtonGrid"
                container
                justifyContent="center"
                alignItems="center"
              >
                <Tooltip title="Vote" arrow TransitionComponent={Zoom}>
                  <span>
                    <IconButton
                      key={`${artist.id}_Like_Button`}
                      onClick={() => handleUpVote(artist.id, upVotes)}
                      disabled={alreadyVoted}
                      ref={votedRef}
                      aria-label="Fire"
                      color="inherit"
                    >
                      🔥
                      <Typography
                        color="inherit"
                        textTransform="lowercase"
                        marginLeft="2px"
                        fontSize="15px"
                        fontWeight="bold"
                      >
                        {upVotes}
                      </Typography>
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
            </>
          ) : (
            <>
              <Grid
                className="likeButtonGrid"
                container
                justifyContent="center"
                alignItems="center"
              >
                <span>
                  <Tooltip
                    title="Log In To Vote!"
                    arrow
                    TransitionComponent={Zoom}
                  >
                    <IconButton
                      key={`${artist.id}likeButton`}
                      ref={votedRef}
                      aria-label="Fire"
                      color="inherit"
                      sx={{ margin: "0" }}
                      disableRipple
                    >
                      🔥
                      <Typography
                        color="inherit"
                        textTransform="lowercase"
                        marginLeft="2px"
                        fontSize="15px"
                        fontWeight="bold"
                      >
                        {upVotes}
                      </Typography>
                    </IconButton>
                  </Tooltip>
                </span>
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs={1.5}>
          {user
            ? user.id === artist.userId && (
                <Tooltip title="Delete Post" arrow TransitionComponent={Zoom}>
                  <IconButton
                    aria-label="delete"
                    color="inherit"
                    onClick={() => {
                      handleDelete(artist.id);
                    }}
                    disabled={disableDelete}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )
            : null}

          <ShareButton artistName={artist.artistName} />

          <Tooltip title="Follow on Instagram" arrow TransitionComponent={Zoom}>
            <IconButton
              href={artist.igLink}
              target="_blank"
              aria-label="instagram"
              color="inherit"
            >
              <InstagramIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Artistv2);
