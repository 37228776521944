import React from "react";
import { Typography, Container } from "@mui/material";
import { Link } from "react-router-dom";

const TermsOfService = () => {
  return (
    <Container
      className="content"
      sx={{ maxWidth: "600px", textAlign: "center" }}
    >
      <Typography
        variant="h3"
        marginTop="30px"
        gutterBottom
        sx={{ marginBottom: "10px" }}
      >
        Website Terms and Conditions of Use
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ marginBottom: "10px" }}>
        1. Terms
      </Typography>

      <Typography variant="body1" gutterBottom sx={{ marginBottom: "10px" }}>
        By accessing this Website, accessible from IndieCharts.io, you are
        agreeing to be bound by these Website Terms and Conditions of Use and
        agree that you are responsible for the agreement with any applicable
        local laws. If you disagree with any of these terms, you are prohibited
        from accessing this site. The materials contained in this Website are
        protected by copyright and trade mark law.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ marginBottom: "10px" }}>
        2. Use License
      </Typography>

      <Typography variant="body1" gutterBottom sx={{ marginBottom: "10px" }}>
        Permission is granted to temporarily download one copy of the materials
        on IndieCharts.io's Website for personal, non-commercial transitory
        viewing only. This is the grant of a license, not a transfer of title,
        and under this license you may not:
      </Typography>

      <Typography variant="body2" gutterBottom sx={{ marginBottom: "10px" }}>
        modify or copy the materials;
      </Typography>
      <Typography variant="body2" gutterBottom sx={{ marginBottom: "10px" }}>
        use the materials for any commercial purpose or for any public display;
      </Typography>
      <Typography variant="body2" gutterBottom sx={{ marginBottom: "10px" }}>
        attempt to reverse engineer any software contained on IndieCharts.io's
        Website;
      </Typography>
      <Typography variant="body2" gutterBottom sx={{ marginBottom: "10px" }}>
        remove any copyright or other proprietary notations from the materials;
        or
      </Typography>
      <Typography variant="body2" gutterBottom sx={{ marginBottom: "10px" }}>
        transferring the materials to another person or "mirror" the materials
        on any other server.
      </Typography>

      <Typography variant="body1" gutterBottom sx={{ marginBottom: "10px" }}>
        This will let IndieCharts.io to terminate upon violations of any of
        these restrictions. Upon termination, your viewing right will also be
        terminated and you should destroy any downloaded materials in your
        possession whether it is printed or electronic format.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ marginBottom: "10px" }}>
        3. Disclaimer
      </Typography>

      <Typography variant="body1" gutterBottom sx={{ marginBottom: "10px" }}>
        All the materials on IndieCharts.io’s Website are provided "as is".
        IndieCharts.io makes no warranties, may it be expressed or implied,
        therefore negates all other warranties. Furthermore, IndieCharts.io does
        not make any representations concerning the accuracy or reliability of
        the use of the materials on its Website or otherwise relating to such
        materials or any sites linked to this Website.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ marginBottom: "10px" }}>
        4. Limitations
      </Typography>

      <Typography variant="body1" gutterBottom sx={{ marginBottom: "10px" }}>
        IndieCharts.io or its suppliers will not be hold accountable for any
        damages that will arise with the use or inability to use the materials
        on IndieCharts.io’s Website, even if IndieCharts.io or an authorize
        representative of this Website has been notified, orally or written, of
        the possibility of such damage. Some jurisdiction does not allow
        limitations on implied warranties or limitations of liability for
        incidental damages, these limitations may not apply to you.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ marginBottom: "10px" }}>
        5. Revisions and Errata
      </Typography>

      <Typography variant="body1" gutterBottom sx={{ marginBottom: "10px" }}>
        The materials appearing on IndieCharts.io’s Website may include
        technical, typographical, or photographic errors. IndieCharts.io will
        not promise that any of the materials in this Website are accurate,
        complete, or current. IndieCharts.io may change the materials contained
        on its Website at any time without notice. IndieCharts.io does not make
        any commitment to update the materials.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ marginBottom: "10px" }}>
        6. Links
      </Typography>

      <Typography variant="body1" gutterBottom sx={{ marginBottom: "10px" }}>
        IndieCharts.io has not reviewed all of the sites linked to its Website
        and is not responsible for the contents of any such linked site. The
        presence of any link does not imply endorsement by IndieCharts.io of the
        site. The use of any linked website is at the user’s own risk.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ marginBottom: "10px" }}>
        7. Site Terms of Use Modifications
      </Typography>

      <Typography variant="body1" gutterBottom sx={{ marginBottom: "10px" }}>
        IndieCharts.io may revise these Terms of Use for its Website at any time
        without prior notice. By using this Website, you are agreeing to be
        bound by the current version of these Terms and Conditions of Use.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ marginBottom: "10px" }}>
        8. Your Privacy
      </Typography>

      <Typography variant="body1" gutterBottom sx={{ marginBottom: "10px" }}>
        Please read our <Link to="/privacypolicy">Privacy Policy</Link>.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ marginBottom: "10px" }}>
        9. Governing Law
      </Typography>

      <Typography variant="body1" gutterBottom sx={{ marginBottom: "10px" }}>
        Any claim related to IndieCharts.io's Website shall be governed by the
        laws of Texas without regards to its conflict of law provisions.
      </Typography>
    </Container>
  );
};

export default TermsOfService;
