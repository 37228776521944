import { useToast } from "@chakra-ui/react";
import {
  Button,
  Modal,
  Fade,
  Box,
  Typography,
  FormControl,
  TextField,
  FormGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import supabase from "../lib/supabaseClient";
import { setUsername, updatingProfile } from "../redux/userSlicer";
import { useTheme } from "@mui/material/styles";
import AvatarWidget from "./AvatarWidget";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  outline: "none",
  borderRadius: "10px",
};

const EditProfileButton = ({
  avatar = "",
  bio = "",
  website = "",
  name = "",
  display = "",
  cover = "",
}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [avatarUrl, setAvatarUrl] = useState(avatar);
  const [userBio, setUserBio] = useState(bio);
  const [userWebsite, setUserWebsite] = useState(website);
  const [username, setUsername2] = useState(name);
  const [coverUrl, setCoverUrl] = useState(cover);
  const [displayName, setDisplayName] = useState(display);
  const [load, setLoad] = useState(false);
  const toast = useToast();
  const user = useSelector((state) => state.user.user);
  const curUser = useSelector((state) => state.user.username);
  const date = new Date();
  const dispatch = useDispatch();
  const theme = useTheme();

  useEffect(() => {
    dispatch(updatingProfile(open));
  }, [open]);

  const handleSubmit = async () => {
    setLoad(true);

    const usernameCheck = await supabase
      .from("userProfiles")
      .select("username")
      .eq("username", username);

    if (usernameCheck.data.length === 0 || curUser === username) {
      try {
        const updateUserProfile = await supabase
          .from("userProfiles")
          .update({
            avatarUrl,
            userBio,
            userWebsite,
            coverUrl,
            displayName,
            username,
            updatedAt: date.toUTCString(),
          })
          .eq("id", user.id);

        if (updateUserProfile) {
          dispatch(setUsername(username));
          setLoad(false);
          handleClose();
        } else {
          toast({
            title: "That username is taken, please try another one.",
            position: "bottom",
            description: "",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          setLoad(false);
          return;
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <>
      <Box>
        {user && (
          <>
            <Button
              variant="contained"
              component="label"
              onClick={handleOpen}
              disableRipple
              style={{ fontWeight: "bold", color: "white" }}
              color="warning"
            >
              Edit Profile
            </Button>

            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              onClose={handleClose}
              closeAfterTransition
            >
              <Fade in={open}>
                <Box sx={style}>
                  <Typography
                    id="transition-modal-title"
                    variant="h5"
                    component="h2"
                    marginBottom="15px"
                    fontWeight="700"
                    align="center"
                  >
                    Edit Your Profile
                  </Typography>

                  <form>
                    <FormGroup sx={{ marginLeft: "35px", marginRight: "35px" }}>
                      <FormControl id="user-name" required>
                        <TextField
                          label="User Name"
                          sx={{
                            marginBottom: "10px",
                          }}
                          type="user-name"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </FormControl>
                      <FormControl id="display-name" required>
                        <TextField
                          label="Display Name"
                          sx={{
                            marginBottom: "10px",
                          }}
                          type="display-name"
                          value={displayName}
                          onChange={(e) => setDisplayName(e.target.value)}
                        />
                      </FormControl>
                      <FormControl id="user-website" required>
                        <TextField
                          sx={{ marginBottom: "10px" }}
                          type="user-website"
                          label="Website"
                          value={userWebsite}
                          onChange={(e) => setUserWebsite(e.target.value)}
                        />
                      </FormControl>
                      <FormControl id="user-bio" required>
                        <TextField
                          sx={{ marginBottom: "10px" }}
                          type="user-bio"
                          label="Bio"
                          value={userBio}
                          multiline
                          rows={4}
                          onChange={(e) => setUserBio(e.target.value)}
                        />
                      </FormControl>
                      <FormControl id="avatar-photo" required>
                        <AvatarWidget
                          url={avatarUrl}
                          user={user.id ? user.id : ""}
                          size={75}
                          onUpload={(url) => {
                            setAvatarUrl(url);
                          }}
                        />
                      </FormControl>
                      <br />
                      <Button
                        component="label"
                        onClick={handleSubmit}
                        style={{ fontWeight: "bold", color: "white" }}
                        color="warning"
                        variant="contained"
                      >
                        Save
                      </Button>
                    </FormGroup>
                  </form>
                </Box>
              </Fade>
            </Modal>
          </>
        )}
      </Box>
    </>
  );
};

export default EditProfileButton;
