import React, { useEffect, useState } from "react";
import { Artist } from "./Artist";
import { Grid } from "@mui/material";
import { Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { useLocation } from "react-router-dom";
import supabase from "./../lib/supabaseClient";
import { Helmet } from "react-helmet";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const ArtistDetails = () => {
  const location = useLocation();
  const [artist, setArtist] = useState();
  const [artistBackup, setArtistBackup] = useState();
  var parser = window.location.href.split("/");
  var url = parser[4];
  if (url.includes("-")) {
    url = url.replaceAll("-", " ");
  }

  if (location.state != null && !artist) {
    setArtist(location.state.artist);
  }

  useEffect(() => {
    if (location.state == null && !artist) {
      const getArtist = async () => {
        if (url.split(" ")[1]) {
          const { data, error } = await supabase
            .from("musicPosts")
            .select()
            .textSearch(
              "artistName",
              `${url.split(" ")[0]} <-> ${url.split(" ")[1]}`,
              {
                type: "websearch",
              }
            );
          if (data) {
            setArtistBackup(data);
          } else {
            let url2 = url.replace(" ", "-");
            const { data, error } = await supabase
              .from("musicPosts")
              .select()
              .textSearch("artistName", url2, {
                type: "websearch",
              });
            if (data) {
              setArtistBackup(data);
            }
          }
        } else {
          const { data, error } = await supabase
            .from("musicPosts")
            .select()
            .textSearch("artistName", `${url}`, {
              type: "websearch",
            });
          if (data) {
            setArtistBackup(data);
          }
        }
      };
      getArtist();
    }
  }, [url]);

  return (
    <div>
      <Helmet>
        <title>IndieCharts.io | {url}</title>
      </Helmet>
      {artist && (
        <Container sx={{ maxWidth: "66vw" }} maxWidth={false}>
          <Grid
            container
            className="listContainer"
            sx={{ gap: 3, marginTop: 5 }}
            alignItems="center"
            justifyContent="center"
            alignContent="center"
            justifyItems="center"
            textAlign="center"
            columns={3}
            flexWrap="wrap"
          >
            {[artist].map((artist, i) => (
              <Grid item minWidth="300px" maxWidth={false} key={artist.id}>
                <Item key={artist.id} sx={{ marginBottom: "10px" }}>
                  <Artist artist={artist} key={artist.id} />
                </Item>
              </Grid>
            ))}
          </Grid>
        </Container>
      )}
      {artistBackup && (
        <Container sx={{ maxWidth: "66vw" }} maxWidth={false}>
          <Grid
            container
            className="listContainer"
            sx={{ gap: 3, marginTop: 5 }}
            alignItems="center"
            justifyContent="center"
            alignContent="center"
            justifyItems="center"
            textAlign="center"
            columns={3}
            flexWrap="wrap"
          >
            {artistBackup.map((artistBackup, i) => (
              <Grid
                item
                minWidth="300px"
                maxWidth={false}
                key={artistBackup.id}
              >
                <Item key={artistBackup.id} sx={{ marginBottom: "10px" }}>
                  <Artist artist={artistBackup} key={artistBackup.id} />
                </Item>
              </Grid>
            ))}
          </Grid>
        </Container>
      )}
    </div>
  );
};

export default ArtistDetails;
