import { useToast } from "@chakra-ui/react";
import {
  Button,
  Modal,
  Fade,
  Box,
  Typography,
  FormControl,
  Divider,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import supabase from "../lib/supabaseClient";
import { loggedInTrue } from "../redux/userSlicer";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import { FormGroup } from "@mui/material";
import { useEffect } from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import { Link } from "react-router-dom";
import TwitterIcon from "@mui/icons-material/Twitter";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "25px",
  outline: "none",
  "&:hover, &:focus": { outline: "none" },
};

const boxSX = {
  boxShadow: 3,
  "&:hover": {
    animation: "pulse 1s infite",
    transition: 0.3,
  },
};

const LoginButton = ({
  loginText = "Log In",
  variantType = "contained",
  sizeType = "inhert",
  fontColorType = "white",
  loginText2,
  ripple = true,
  fontColor = "warning",
  weightType = "bold",
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const toast = useToast();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  async function signIn(provider) {
    try {
      // eslint-disable-next-line no-unused-vars
      const { user, session, error } = await supabase.auth.signIn({
        email: email,
        password: password,
        provider: provider,
      });
      if (!error) {
        dispatch(loggedInTrue());
        toast({
          title: "You are now logging in.",
          position: "bottom",
          description: "",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "You have entered the wrong password, please try again.",
          position: "bottom",
          description: "",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function signUp() {
    try {
      // eslint-disable-next-line no-unused-vars
      const { user, session, error } = await supabase.auth.signUp({
        email: email,
        password: password,
      });
      dispatch(loggedInTrue());
      toast({
        title:
          "Thank you for registering! Check your email for your confirmation link.",
        position: "bottom",
        description: "",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  async function resetPassword() {
    // eslint-disable-next-line no-unused-vars
    const { data, error } = await supabase.auth.api.resetPasswordForEmail(
      email
    );
    toast({
      title: "We have sent a password reset link to your email.",
      position: "bottom",
      description: "",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  }

  useEffect(() => {
    supabase.auth.onAuthStateChange(async (event, session) => {
      if (event === "PASSWORD_RECOVERY") {
        const newPassword = prompt(
          "What would you like your new password to be?"
        );
        const { data, error } = await supabase.auth.update({
          password: newPassword,
        });

        if (data) alert("Password updated successfully!");
        if (error) alert("There was an error updating your password.");
      }
    });
  }, []);

  return (
    <>
      <Box>
        <Button
          style={{
            fontWeight: `${weightType}`,
            color: `${fontColorType}`,
          }}
          color={fontColor}
          variant={variantType}
          onClick={handleOpen}
          size={sizeType}
          disableRipple={ripple}
        >
          {loginText}
        </Button>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
        >
          <Fade in={open}>
            <Box sx={style}>
              <Typography
                id="transition-modal-title"
                variant="h5"
                fontWeight="700"
                align="center"
              >
                Welcome to IndieCharts
              </Typography>
              <br></br>
              <form>
                <FormGroup sx={{ marginLeft: "35px", marginRight: "35px" }}>
                  <FormControl id="emailInput">
                    <InputLabel htmlFor="userEmail">Email</InputLabel>
                    <OutlinedInput
                      id="userEmail"
                      sx={{ marginBottom: "15px" }}
                      type="text"
                      label="Email"
                      autoComplete="username email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormControl>
                  <FormControl id="passwordInput">
                    <InputLabel htmlFor="userPassword">Password</InputLabel>
                    <OutlinedInput
                      id="userPassword"
                      sx={{ marginBottom: "15px" }}
                      type={showPassword ? "text" : "password"}
                      label="Password"
                      inputProps={{ autoComplete: "new-password" }}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword()}
                            onMouseDown={(e) => handleMouseDownPassword(e)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <Button
                    component="label"
                    variant="text"
                    size="small"
                    onClick={() => {
                      resetPassword();
                    }}
                    style={{
                      marginBottom: "15px",
                      textTransform: "none",
                    }}
                    disabled={!email.trim()}
                  >
                    Forgot your password?
                  </Button>
                  <Button
                    component="label"
                    onClick={() => {
                      signIn();
                    }}
                    style={{
                      fontWeight: "bold",
                      color: "white",
                      marginBottom: "15px",
                    }}
                    variant="contained"
                    disabled={!password.trim() || !email.trim()}
                  >
                    Log In
                  </Button>
                  <Button
                    component="label"
                    onClick={() => {
                      signUp();
                    }}
                    style={{
                      fontWeight: "bold",
                      color: "white",
                      marginBottom: "10px",
                    }}
                    variant="contained"
                    color="success"
                    disabled={!password.trim() || !email.trim()}
                  >
                    Register
                  </Button>
                  {/* <Divider>OR</Divider>
                 <Button
                  style={{ backgroundColor: "#1db954", color: "white" }}
                  onClick={() => {
                    signIn();
                  }}
                  startIcon={<FaSpotify />}
                >
                  Continue with Spotify
                </Button> */}
                  {/* <Button
                    sx={boxSX}
                    style={{
                      backgroundColor: "#4267B2",
                      color: "white",
                      marginTop: "10px",
                    }}
                    onClick={() => {
                      signIn("facebook");
                    }}
                    startIcon={<FacebookIcon />}
                  >
                    Continue with Facebook
                  </Button> */}
                  {/* <Button
                    sx={boxSX}
                    style={{
                      backgroundColor: "#1DA1F2",
                      color: "white",
                      marginTop: "10px",
                    }}
                    onClick={() => {
                      signIn("twitter");
                    }}
                    startIcon={<TwitterIcon />}
                  >
                    Continue with Twitter
                  </Button> */}
                  {/* <Button
                  sx={boxSX}
                  style={{
                    backgroundColor: "#ededed",
                    color: "black",
                    marginTop: "10px",
                  }}
                  onClick={() => {
                    signIn("google");
                  }}
                  startIcon={<GoogleIcon />}
                >
                  Continue with Google
                </Button> */}

                  <Typography
                    variant="body2"
                    align="center"
                    maxWidth="250px"
                    sx={{ marginTop: "15px", fontSize: "10px" }}
                  >
                    By continuing, you agree to IndieCharts'{" "}
                    <Link
                      to="/termsofservice"
                      style={{
                        textDecoration: "underline",
                      }}
                      target="_blank"
                    >
                      Terms of Service
                    </Link>{" "}
                    and acknowledge you've read our{" "}
                    <Link
                      to="/privacypolicy"
                      style={{ textDecoration: "underline" }}
                      target="_blank"
                    >
                      Privacy Policy
                    </Link>
                    .
                  </Typography>
                </FormGroup>
              </form>
            </Box>
          </Fade>
        </Modal>
      </Box>
    </>
  );
};

export default LoginButton;
