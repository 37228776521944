import { useToast } from "@chakra-ui/react";
import {
  Button,
  Modal,
  Fade,
  Box,
  Typography,
  FormControl,
  TextField,
  FormGroup,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import supabase from "../lib/supabaseClient";
import { addPost } from "../redux/userSlicer";
import LoginButton from "./LoginButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  outline: "none",
  "&:hover, &:focus": { outline: "none" },
  overflow: "hidden",
  borderRadius: "10px",
};

const NewArtist = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [artistName, setName] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [genre, setGenre] = useState("");
  const [songName, setSongName] = useState("");
  const [songLink, setSongLink] = useState("");
  let [igLink, setIgLink] = useState("");
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();
  const toast = useToast();
  const user = useSelector((state) => state.user.user);
  const date = new Date();
  const numPosts = useSelector((state) => state.user.numPosts);
  const isSubscribed = useSelector((state) => state.user.isSubscribed);

  const handleChange = (event) => {
    setGenre(event.target.value);
  };

  const handleSubmit = async () => {
    setLoad(true);
    let songLinkCheck = false;
    const resultTidal =
      songLink.includes("tidal.com/browse/track") ||
      songLink.includes("tidal.com/track") ||
      songLink.includes("Tidal.com/browse/track") ||
      songLink.includes("Tidal.com/track");
    const resultIg =
      igLink.includes("instagram.com") || igLink.includes("Instagram.com");
    const resultSpotify =
      songLink.includes("open.spotify.com/track") ||
      songLink.includes("Open.spotify.com/track");
    const resultYoutube =
      songLink.includes("youtube.com/watch?v=") ||
      songLink.includes("youtu.be/") ||
      songLink.includes("Youtube.com/watch?v=") ||
      songLink.includes("Youtu.be/");

    if (
      !artistName ||
      !city ||
      !state ||
      !genre ||
      !songLink ||
      !igLink ||
      !songName
    ) {
      toast({
        title: "Please fill in all the fields correctly.",
        position: "bottom",
        description: "",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setLoad(false);
      return;
    }

    if (!resultIg) {
      toast({
        title: "Please fix your Instagram link.",
        position: "bottom",
        description: "",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setLoad(false);
      return;
    }

    if (resultYoutube || resultTidal || resultSpotify) {
      songLinkCheck = true;
    } else {
      songLinkCheck = false;
    }

    if (!songLinkCheck) {
      toast({
        title:
          "Please make sure your song is a single track from Spotify, Tidal or YouTube. No albums or playlists.",
        position: "bottom",
        description: "",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setLoad(false);
      return;
    }

    if (numPosts === 1 && !isSubscribed) {
      toast({
        title:
          "Sorry, you have reached your post limit. Subscribe or delete a post to add more music.",
        position: "bottom",
        description: "",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setLoad(false);
      return;
    }

    if (numPosts === 5) {
      toast({
        title:
          "Sorry, you have reached your post limit. Delete a post to add more music.",
        position: "bottom",
        description: "",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setLoad(false);
      return;
    }

    if (
      !igLink.includes("https://") &&
      !igLink.includes("http://") &&
      !igLink.includes("www.")
    ) {
      igLink = "https://" + igLink;
    }

    const insertArtist = await supabase.from("musicPosts").insert([
      {
        artistName,
        city,
        state,
        genre,
        songLink,
        igLink,
        songName,
        upVotesCount: 0,
        createdAt: date.toUTCString(),
        updatedAt: date.toUTCString(),
        userId: user.id,
      },
    ]);

    if (insertArtist) {
      setName("");
      setCity("");
      setState("");
      setGenre("");
      setSongName("");
      setSongLink("");
      setIgLink("");
      handleClose();
      setLoad(false);
      dispatch(addPost());
    }
  };

  return (
    <>
      <Box>
        {user ? (
          <Button
            style={{ fontWeight: "bold", color: "white" }}
            color="warning"
            variant="contained"
            onClick={handleOpen}
          >
            Add Music
          </Button>
        ) : (
          <LoginButton loginText={"Add Music"} loginText2={undefined} />
        )}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
        >
          <Fade in={open}>
            {/* <Grid container sx={style} align="center" direction="column"> */}
            <Box sx={style}>
              <Typography
                id="transition-modal-title"
                variant="h5"
                component="h2"
                marginBottom="15px"
                fontWeight="700"
                align="center"
              ></Typography>

              <form>
                <FormGroup sx={{ marginLeft: "35px", marginRight: "35px" }}>
                  <FormControl id="artist-name" required>
                    <TextField
                      label="Artist Name"
                      sx={{
                        marginBottom: "10px",
                      }}
                      type="artist-name"
                      value={artistName}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </FormControl>
                  <FormControl id="artist-city" required>
                    <TextField
                      sx={{ marginBottom: "10px" }}
                      type="artist-city"
                      label="Artist City"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </FormControl>
                  <FormControl id="artist-state" required>
                    <TextField
                      sx={{ marginBottom: "10px" }}
                      type="artist-state"
                      label="Artist State"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                    />
                  </FormControl>
                  <FormControl>
                    <InputLabel id="artist-genre">Genre</InputLabel>
                    <Select
                      labelId="artist-genre"
                      id="artist-genre"
                      value={genre}
                      label="Artist Genre"
                      onChange={handleChange}
                    >
                      <MenuItem value={"Pop"}>Pop</MenuItem>
                      <MenuItem value={"Hip-Hop"}>Hip-Hop</MenuItem>
                      <MenuItem value={"EDM"}>EDM</MenuItem>
                      <MenuItem value={"Rock"}>Rock</MenuItem>
                      <MenuItem value={"R&B"}>R&B</MenuItem>
                      <MenuItem value={"Latin"}>Latin</MenuItem>
                      <MenuItem value={"K-Pop"}>K-Pop</MenuItem>
                      <MenuItem value={"Country"}>Country</MenuItem>
                      <MenuItem value={"Classical"}>Classical</MenuItem>
                      <MenuItem value={"Metal"}>Metal</MenuItem>
                      <MenuItem value={"Other"}>Other</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl id="artist-songname" required>
                    <TextField
                      sx={{ marginBottom: "10px" }}
                      type="artist-songname"
                      label="Song Name"
                      value={songName}
                      onChange={(e) => setSongName(e.target.value)}
                    />
                  </FormControl>
                  <FormControl id="artist-songlink" required>
                    <TextField
                      sx={{ marginBottom: "10px" }}
                      type="artist-songlink"
                      label="Tidal, Spotify or YouTube Song Link"
                      value={songLink}
                      onChange={(e) => setSongLink(e.target.value)}
                    />
                  </FormControl>
                  <FormControl id="artist-iglink" required>
                    <TextField
                      sx={{ marginBottom: "10px" }}
                      type="artist-iglink"
                      label="Artist Instagram"
                      value={igLink}
                      onChange={(e) => setIgLink(e.target.value.toLowerCase())}
                    />
                  </FormControl>

                  <Button
                    component="label"
                    onClick={handleSubmit}
                    style={{ fontWeight: "bold", color: "white" }}
                    color="warning"
                    variant="contained"
                    disabled={!artistName.trim()}
                  >
                    Save
                  </Button>
                </FormGroup>
              </form>
            </Box>
            {/* </Grid> */}
          </Fade>
        </Modal>
      </Box>
    </>
  );
};

export default NewArtist;
