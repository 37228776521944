import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/es/storage";
import themeReducer from "./themeSlicer";
import userReducer from "./userSlicer";
import { getPersistConfig } from "redux-deep-persist";
import thunk from "redux-thunk";

const rootReducer = combineReducers({
  user: userReducer,
  theme: themeReducer,
});

const persistConfig = getPersistConfig({
  key: "root",
  version: 1,
  storage,
  blacklist: [],
  rootReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk,
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
