import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  theme: true,
  sTheme: 0,
};

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    dark: (state) => {
      state.theme = false;
      state.sTheme = 1;
    },
    light: (state) => {
      state.theme = true;
      state.sTheme = 0;
    },
  },
});

export const { dark, light } = themeSlice.actions;

export default themeSlice.reducer;
