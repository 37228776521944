import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Route, Routes } from "react-router-dom";
import NavBar from "./components/NavBar";
import ListArtists from "./components/ListArtists";
import { useSelector } from "react-redux";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import Footer from "./components/Footer";
import MessageUser from "./components/MessageUser";
import ArtistDetails from "./components/ArtistDetails";
import AdSense from "./components/AdSense";
import Events from "./components/Events";
import Profile from "./components/Profile";
import { ListArtistsv2 } from "./components/ListArtistsv2";
import { Container } from "@mui/material";
import { Helmet } from "react-helmet";

import TopArtists2 from "./components/TopArtists2";
import ScrollToTopButton from "./components/ScrollToTopButton";

export default function App() {
  // @ts-ignore
  const themeSetting = useSelector((state) => state.theme.theme);

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: themeSetting ? "dark" : "light",
        },
      }),
    [themeSetting]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <Helmet>
          <title>IndieCharts.io</title>
          <meta
            name="description"
            content="Explore music by independent artists worldwide & vote for your favorite songs!"
          />
        </Helmet>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <NavBar />
                <ListArtistsv2 />

                <Footer />
              </>
            }
          />
          <Route
            path="*"
            element={
              <>
                <NavBar />
                <ListArtists />

                <Footer />
              </>
            }
          />
          <Route
            path="/privacypolicy"
            element={
              <>
                <NavBar />
                <PrivacyPolicy />
                <Footer />
              </>
            }
          />
          <Route
            path="/termsofservice"
            element={
              <>
                <NavBar />
                <TermsOfService />
                <Footer />
              </>
            }
          />
          <Route
            path="/city/:artistCity"
            element={
              <>
                <NavBar />
                <TopArtists2 />

                <Footer />
              </>
            }
          />
          <Route
            path="/state/:artistState"
            element={
              <>
                <NavBar />
                <TopArtists2 />

                <Footer />
              </>
            }
          />
          <Route
            path="/genre/:artistGenre"
            element={
              <>
                <NavBar />
                <TopArtists2 />

                <Footer />
              </>
            }
          />
          <Route
            path="/events"
            element={
              <>
                <NavBar />
                <Events />
                <Footer />
              </>
            }
          />
          {/* <Route
            path="/posts"
            element={
              <>
                <NavBar />
                <ViewMyPosts />
                <Footer />
              </>
            }
          />
          <Route
            path="/likes"
            element={
              <>
                <NavBar />
                <ViewMyLikes />
                <Footer />
              </>
            }
          /> */}
          <Route
            path="/:username"
            element={
              <>
                <NavBar />
                <Profile />
                <Footer />
              </>
            }
          />
        </Routes>
      </CssBaseline>
    </ThemeProvider>
  );
}
