import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import supabase from "../lib/supabaseClient";
import { Button, Grid } from "@mui/material";
import { dark, light } from "../redux/themeSlicer";
import NewArtist from "./NewArtist";
import { useEffect, useState } from "react";
import { loggedInFalse, setUser } from "../redux/userSlicer";
import { useToast } from "@chakra-ui/react";
import LoginButton from "./LoginButton";
import SubscribeButton from "./SubscribeButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import {
  setNumPosts,
  subscribedFalse,
  subscribedTrue,
  setUsername,
  setAvatarLink,
} from "../redux/userSlicer";

function NavBar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [load, setLoad] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [session, setSession] = useState(null);
  const toast = useToast();
  const user = supabase.auth.user();
  const user2 = useSelector((state) => state.user.user);
  const themeSetting = useSelector((state) => state.theme.theme);
  const dispatch = useDispatch();
  const numPosts = useSelector((state) => state.user.numPosts);
  const isSubscribed = useSelector((state) => state.user.isSubscribed);
  const username = useSelector((state) => state.user.username);
  const date = new Date();
  const avatar = useSelector((state) => state.user.avatarLink);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  async function SignOut() {
    setLoad(true);
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.log(error);
    }
    handleCloseUserMenu();
    dispatch(loggedInFalse());
    dispatch(setUser(null));
    toast({
      title: "You are now logged out.",
      position: "bottom",
      description: "",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    setLoad(false);
  }

  useEffect(() => {
    setSession(supabase.auth.session());
    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, []);

  useEffect(() => {
    if (user) {
      if (user !== user2) {
        dispatch(setUser(user));
        const checkAccount = async (id) => {
          try {
            const { data } = await supabase
              .from("users")
              .select("isSubscribed, numPosted, username")
              .eq("id", id);
            dispatch(setNumPosts(data[0].numPosted));
            if (data[0].isSubscribed) {
              dispatch(subscribedTrue());
            } else {
              dispatch(subscribedFalse());
            }
          } catch (err) {
            console.log(err);
          }

          try {
            const { data } = await supabase
              .from("userProfiles")
              .select("username, avatarUrl")
              .eq("id", id);
            if (!data[0].username) {
              console.log("updating username");
              const updateUsername = await supabase
                .from("userProfiles")
                .update({
                  username: user.email,
                  updatedAt: date.toUTCString(),
                })
                .eq("id", user.id);
              dispatch(setUsername(user.email));
            } else {
              dispatch(setUsername(data[0].username));
              if (data[0].avatarUrl) {
                dispatch(
                  setAvatarLink(
                    "https://wgnmpgxnshaydtslnahz.supabase.co/storage/v1/object/public/user-avatarphotos/" +
                      data[0].avatarUrl
                  )
                );
              }
            }
          } catch (err) {
            console.log(err);
          }
        };
        checkAccount(user.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, user2, avatar]);

  return (
    <AppBar position="sticky" color="inherit">
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          id="mainToolbar"
          sx={{
            justifyContent: "center",
            display: "flex",
            justifyItems: "center",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <ExpandMoreIcon />
            </IconButton>
            <Box
              alignContent="center"
              justifyContent="center"
              alignItems="center"
              justifyItems="center"
              textAlign="center"
              display="flex"
            >
              <Link to="/">
                <Typography variant="h6" color="warning" fontWeight="700">
                  IndieCharts
                </Typography>
              </Link>
            </Box>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: {
                  xs: "block",
                  md: "none",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  align: "center",
                  textAlign: "center",
                  display: "flex",
                  marginTop: "45px",
                },
              }}
            >
              <MenuItem
                sx={{
                  justifyContent: "center",
                }}
                onClick={handleCloseNavMenu}
              >
                <NewArtist />
              </MenuItem>
              {!isSubscribed && user && (
                <MenuItem
                  onClick={handleCloseNavMenu}
                  sx={{
                    justifyContent: "center",
                  }}
                >
                  <SubscribeButton />
                </MenuItem>
              )}
              <MenuItem
                onClick={handleCloseNavMenu}
                sx={{
                  justifyContent: "center",
                }}
              >
                <Button
                  href="https://distrokid.com/vip/seven/857434"
                  target="_blank"
                  color="warning"
                  size="small"
                  onClick={handleCloseNavMenu}
                  style={{
                    marginLeft: "5px",
                    marginRight: "5px",
                    fontWeight: "700",
                  }}
                >
                  SELL MUSIC
                </Button>
              </MenuItem>
              <MenuItem
                onClick={handleCloseNavMenu}
                sx={{
                  justifyContent: "center",
                }}
              >
                <Button
                  href="https://dallasopenmic.com"
                  target="_blank"
                  color="warning"
                  size="small"
                  onClick={handleCloseNavMenu}
                  style={{
                    marginLeft: "5px",
                    marginRight: "5px",
                    fontWeight: "700",
                  }}
                >
                  PERFORM
                </Button>
              </MenuItem>
            </Menu>
          </Box>
          <Box
            onClick={handleCloseNavMenu}
            alignItems="center"
            sx={{
              flexGrow: 0,
              display: { xs: "none", md: "flex" },
            }}
          >
            <NewArtist />
            {!isSubscribed && user && (
              <Box sx={{ marginLeft: "5px" }}>
                <SubscribeButton />
              </Box>
            )}
            <Button
              href="https://distrokid.com/vip/seven/857434"
              target="_blank"
              color="warning"
              size="small"
              style={{
                marginLeft: "5px",
                marginRight: "5px",
                fontWeight: "700",
              }}
            >
              SELL MUSIC
            </Button>
            <Button
              href="https://dallasopenmic.com"
              target="_blank"
              color="warning"
              size="small"
              style={{
                marginLeft: "5px",
                marginRight: "5px",
                fontWeight: "700",
              }}
            >
              PERFORM
            </Button>
          </Box>
          <Grid
            container
            display="flex"
            direction="row"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            spacing={0}
            width="auto"
            margin="auto"
          >
            <Link to="/">
              {isSubscribed && user && (
                <Grid item>
                  <Typography
                    variant="h5"
                    paddingRight="182px"
                    sx={{
                      display: { xs: "none", md: "flex" },
                      fontWeight: 700,
                      color: "inherit",
                    }}
                  >
                    IndieCharts
                  </Typography>
                </Grid>
              )}

              {!isSubscribed && user && (
                <Grid item>
                  <Typography
                    variant="h5"
                    paddingRight="284px"
                    sx={{
                      display: { xs: "none", md: "flex" },
                      fontWeight: 700,
                      color: "inherit",
                    }}
                  >
                    IndieCharts
                  </Typography>
                </Grid>
              )}

              {!user && (
                <Grid item>
                  <Typography
                    variant="h5"
                    paddingRight="163px"
                    sx={{
                      display: { xs: "none", md: "flex" },
                      fontWeight: 700,
                      color: "inherit",
                    }}
                  >
                    IndieCharts
                  </Typography>
                </Grid>
              )}
            </Link>
          </Grid>

          {themeSetting === false ? (
            <IconButton
              onClick={() => dispatch(light())}
              sx={{ mr: 1 }}
              color="inherit"
            >
              <DarkModeIcon />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => dispatch(dark())}
              sx={{ mr: 1 }}
              color="inherit"
            >
              <LightModeIcon />
            </IconButton>
          )}
          {user ? (
            <Box sx={{ flexGrow: 0 }} ml="5px">
              <IconButton onClick={handleOpenUserMenu}>
                {!avatar.includes("null") ? (
                  <Avatar alt="User Avatar" src={avatar} />
                ) : (
                  <MenuIcon />
                )}
              </IconButton>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <a href={`/${username}`}>
                  <MenuItem key="profileLink" onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">Profile</Typography>
                  </MenuItem>
                </a>

                {/* <Link to={"/posts"}>
                  <MenuItem key="viewPosts" onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">My Posts</Typography>
                  </MenuItem>
                </Link> */}
                <MenuItem key="postsLeft">
                  <Typography textAlign="center">
                    Posts Left: &nbsp;
                    {isSubscribed ? 5 - numPosts : 1 - numPosts}
                  </Typography>
                </MenuItem>
                {/* <Link to={"/likes"}>
                  <MenuItem key="viewLikes" onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">My Likes</Typography>
                  </MenuItem>
                </Link> */}
                <MenuItem key="logout" onClick={() => SignOut()}>
                  <Typography textAlign="center">Log Out</Typography>
                </MenuItem>
              </Menu>
            </Box>
          ) : (
            <>
              <LoginButton loginText={"Log In"} loginText2={undefined} />
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default NavBar;
