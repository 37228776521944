import React, { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Popper from "@mui/material/Popper";
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import RedditIcon from "@mui/icons-material/Reddit";
import LinkIcon from "@mui/icons-material/Link";
import ShareIcon from "@mui/icons-material/Share";
import { ClickAwayListener, IconButton, Tooltip } from "@mui/material";
import { useToast } from "@chakra-ui/react";
import Zoom from "@mui/material/Zoom";

const useStyles = {
  typography: {
    //padding: theme.spacing(2),
  },
  btn: {
    backgroundColor: "#26a27b",
    color: "rgba(255,255,255,0.9)",
    fontWeight: 500,
    "&:hover": {
      backgroundColor: "#26a27b",
    },
  },
};

export default function ShareButton({ artistName }) {
  const classes = useStyles;
  const toast = useToast();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open2 = Boolean(anchorEl);
  const handleClickAway = () => {
    setAnchorEl(false);
  };

  const handleShare = (e) => {
    e.preventDefault();

    const ahref = window.location.href;
    const encodedAhref = encodeURIComponent(ahref);
    var link;

    switch (e.currentTarget.id) {
      case "facebook":
        link = `https://www.facebook.com/sharer/sharer.php?u=${ahref}`;
        open(link);
        break;

      case "twitter":
        link = `https://twitter.com/intent/tweet?url=${encodedAhref}&text=Vote%20For%20${artistName}!&via=indiechartsio`;
        open(link);
        break;

      case "reddit":
        link = `https://www.reddit.com/submit?url=${encodedAhref}`;
        open(link);
        break;

      case "copy":
        navigator.clipboard.writeText(ahref);
        toast({
          title: "Link copied!",
          position: "bottom",
          description: "",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        break;

      default:
        break;
    }
  };

  const open = (socialLink) => {
    window.open(socialLink, "_blank");
  };

  return (
    <PopupState variant="popper">
      {(popupState) => (
        <ClickAwayListener onClickAway={handleClickAway}>
          <div onClick={handleClick}>
            <Tooltip title="Share Post" arrow TransitionComponent={Zoom}>
              <IconButton color="inherit" {...bindToggle(popupState)}>
                <ShareIcon />
              </IconButton>
            </Tooltip>
            {open2 ? (
              <Popper
                open={anchorEl}
                anchorEl={anchorEl}
                {...bindPopper(popupState)}
                transition
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper>
                      <List dense={true} className={classes.paper}>
                        <ListItem button id="facebook" onClick={handleShare}>
                          <ListItemIcon>
                            <FacebookIcon />
                          </ListItemIcon>
                          <ListItemText
                            sx={{ ml: "-10px" }}
                            primary="Facebook"
                          />
                        </ListItem>
                        <ListItem button id="twitter" onClick={handleShare}>
                          <ListItemIcon>
                            <TwitterIcon />
                          </ListItemIcon>
                          <ListItemText
                            sx={{ ml: "-10px" }}
                            primary="Twitter"
                          />
                        </ListItem>
                        <ListItem button id="reddit" onClick={handleShare}>
                          <ListItemIcon>
                            <RedditIcon />
                          </ListItemIcon>
                          <ListItemText sx={{ ml: "-10px" }} primary="Reddit" />
                        </ListItem>
                        <ListItem button id="copy" onClick={handleShare}>
                          <ListItemIcon>
                            <LinkIcon />
                          </ListItemIcon>
                          <ListItemText
                            sx={{ ml: "-10px" }}
                            primary="Copy Link"
                          />
                        </ListItem>
                      </List>
                    </Paper>
                  </Fade>
                )}
              </Popper>
            ) : null}
          </div>
        </ClickAwayListener>
      )}
    </PopupState>
  );
}
